import React from "react";
import { kebabCase } from "lodash";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const TagsMenu = () => {
  const {
    allMarkdownRemark: { group: tags }
  } = useStaticQuery(graphql`
    query TagsMenuQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  return (
    <NavBar>
      <MenuItems>
        <List>
          <MenuItem to={`/blog`} activeClassName="active">
            All
          </MenuItem>
        </List>
        {tags.map(tag => (
          <List>
            <MenuItem
              to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}
              activeClassName="active"
            >
              {tag.fieldValue}
            </MenuItem>
          </List>
        ))}
      </MenuItems>
    </NavBar>
  );
};

export default TagsMenu;

const NavBar = styled.nav`
  padding: 20px 20px 0 20px;
  flex-wrap: wrap;
  align-items: center;
`;

const MenuItems = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;


const List = styled.li`
  margin: 10px 5px;
`;

const MenuItem = styled(Link)`
  background-color: #5065ff;
  border-radius: 20px;
  color: #ffffff;
  padding: 6px 15px;
  margin: 10px 5px;
  &:hover {
    background: #F77DD6;
  }
  &.active {
    background: linear-gradient(270deg, rgb(71, 228, 193), rgb(7, 205, 221));
  }
`;
